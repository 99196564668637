<template>
    <div>
        <!-- FIRST PAGE EVALVATION -->
        <div v-if="!is_internal_questionnaire && (q_type == 1 || q_type == 4) && page_data[active_page_id] && page_data[active_page_id].order_number == 1"
            class="evalvation_question_holder">
            <div v-for="question in page_questions" :key="question.id" class="evalvation_question_holder">
                <div class="question_title_row">
                    <span class="as_link icon edit" title="Uredi vprašanje" v-if="build_mode && getUserType == 2">
                        <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({
                            component: 'evalvation/EditQuestionnaireQuestion',
                            data: { title: question.title, id: question.id, order_number: question.order_number, description: question.description, 
                                    breakk: question.break, q_type: q_type, images: question.images, skippable: question.skippable, 
                                    improvements: question.improvements, stopnja: question.stopnja, vrsta: question.vrsta }
                        })" />
                    </span>

                    <span class="question_title unnumbered">
                        <b>{{ question.title }}</b>
                    </span>
                </div>

                <div class="question_description" v-if="question.description && question.description !== ''"
                    v-html="question.description"></div>

                <div class="general_data">
                    <div v-if="!build_mode && programs_id.length > 1" class="question_description">
                        <div v-for="(program, i) of programs_id" :key="program">
                            <span class="numbered">{{ i + 1 }}. </span>
                            <input class="short_text" type="text" :class="textarea_sample" :disabled="disable_input"
                                :value="multi_program_question_answer(question.id, program)" size="35"
                                @change="multi_program_saveAnswer(question.id, program, $event)" />
                        </div>
                    </div>
                    <div v-else class="question_description">
                        <input class="short_text" type="text" :class="textarea_sample" :disabled="disable_input"
                            :value="question_answer(question.id)" size="35" @change="saveAnswer(question.id, $event)" />
                    </div>
                </div>
            </div>

            <div class="general_data">
                <div class="question_title"><b>Ime študijskega programa</b></div>
                <div class="question_description" v-if="program_data.name">
                    <div>
                        <div><span v-if="program_data.additional_programs" class="numbered">1. </span><span>{{
                            program_data.name }}</span></div>
                    </div>
                    <div v-if="program_data.additional_programs">
                        <div v-for="(aprogram, id, i) in program_data.additional_programs" :key="id">
                            <span class="numbered">{{ i + 2 }}. </span><span>{{ aprogram.name }}</span>
                        </div>
                    </div>
                </div>
                <br v-if="build_mode">
                <div class="question_title"><b>Stopnja študijskega programa</b></div>
                <div class="question_description" v-if="program_data.name">
                    <div>
                        <div><span v-if="program_data.additional_programs" class="numbered">1. </span><span>{{
                            program_data.stopnja }}</span></div>
                    </div>
                    <div v-if="program_data.additional_programs">
                        <div v-for="(aprogram, id, i) in program_data.additional_programs" :key="id">
                            <span class="numbered">{{ i + 2 }}. </span><span>{{ aprogram.stopnja }}</span>
                        </div>
                    </div>
                </div>
                <br v-if="build_mode">
                <div class="question_title"><b>Vrsta študijskega programa</b></div>
                <div class="question_description" v-if="program_data.name">
                    <div>
                        <div><span v-if="program_data.additional_programs" class="numbered">1. </span><span>{{
                            program_data.vrsta }}</span></div>
                    </div>
                    <div v-if="program_data.additional_programs">
                        <div v-for="(aprogram, id, i) in program_data.additional_programs" :key="id">
                            <span class="numbered">{{ i + 2 }}. </span><span>{{ aprogram.vrsta }}</span>
                        </div>
                    </div>
                </div>
                <br v-if="build_mode">
                <div class="question_title"><b>Način izvajanja študija</b></div>
                <div class="question_description" v-if="program_data.name">
                    <div>
                        <div><span v-if="program_data.additional_programs" class="numbered">1. </span><span>{{
                            program_data.nacin_studija }}</span></div>
                    </div>
                    <div v-if="program_data.additional_programs">
                        <div v-for="(aprogram, id, i) in program_data.additional_programs" :key="id">
                            <span class="numbered">{{ i + 2 }}. </span><span>{{ aprogram.nacin_studija }}</span>
                        </div>
                    </div>
                </div>
                <br v-if="build_mode">
                <div class="question_title"><b>Dvopredmetni program</b></div>
                <div class="question_description" v-if="program_data.name">
                    <div>
                        <div><span v-if="program_data.additional_programs" class="numbered">1. </span><span>{{
                            program_data.dvopredmetni == '1' ? 'Da' : 'Ne' }}</span></div>
                    </div>
                    <div v-if="program_data.additional_programs">
                        <div v-for="(aprogram, id, i) in program_data.additional_programs" :key="id">
                            <span class="numbered">{{ i + 2 }}. </span><span>{{ aprogram.dvopredmetni == '1' ? 'Da' : 'Ne'
                            }}</span>
                        </div>
                    </div>
                </div>
                <br v-if="build_mode">
                <div class="question_title"><b>Pedagoški program</b></div>
                <div class="question_description" v-if="program_data.name">
                    <div>
                        <div><span v-if="program_data.additional_programs" class="numbered">1. </span><span>{{
                            program_data.pedagoski == '1' ? 'Da' : 'Ne' }}</span></div>
                    </div>
                    <div v-if="program_data.additional_programs">
                        <div v-for="(aprogram, id, i) in program_data.additional_programs" :key="id">
                            <span class="numbered">{{ i + 2 }}. </span><span>{{ aprogram.pedagoski == '1' ? 'Da' : 'Ne'
                            }}</span>
                        </div>
                    </div>
                </div>
                <br v-if="build_mode">
                <div class="question_title"><b>Interdisciplinarni program</b></div>
                <div class="question_description" v-if="program_data.name">
                    <div>
                        <div><span v-if="program_data.additional_programs" class="numbered">1. </span><span>{{
                            program_data.interdisciplinarni == '1' ? 'Da' : 'Ne' }}</span></div>
                    </div>
                    <div v-if="program_data.additional_programs">
                        <div v-for="(aprogram, id, i) in program_data.additional_programs" :key="id">
                            <span class="numbered">{{ i + 2 }}. </span><span>{{ aprogram.interdisciplinarni == '1' ? 'Da' : 'Ne'
                            }}</span>
                        </div>
                    </div>
                </div>
                <br v-if="build_mode">
                <div class="question_title"><b>Skupni program</b></div>
                <div class="question_description" v-if="program_data.name">
                    <div>
                        <div><span v-if="program_data.additional_programs" class="numbered">1. </span><span>{{
                            program_data.skupni == '1' ? 'Da' : 'Ne' }}</span></div>
                    </div>
                    <div v-if="program_data.additional_programs">
                        <div v-for="(aprogram, id, i) in program_data.additional_programs" :key="id">
                            <span class="numbered">{{ i + 2 }}. </span><span>{{ aprogram.skupni == '1' ? 'Da' : 'Ne'
                            }}</span>
                        </div>
                    </div>
                </div>
                <!-- <br v-if="build_mode">
                <div class="question_title"><b>Akreditacija</b></div>
                <div class="question_description" v-if="program_data.name">
                    <div><div><span v-if="program_data.additional_programs" class="numbered">1. </span><span>{{ program_data.akreditacija_od }}</span></div></div>
                    <div v-if="program_data.additional_programs">
                        <div v-for="(aprogram, id, i) in program_data.additional_programs" :key="id" >
                            <span class="numbered">{{i+2}}. </span><span>{{ aprogram.akreditacija_od }}</span>
                        </div>
                    </div>
                </div> -->
                <br v-if="build_mode">
                <div class="question_title"><b>Dodaten opis</b></div>
                <div class="question_description" v-if="program_data.name">
                    <div>
                        <div><span v-if="program_data.additional_programs" class="numbered">1. </span><span>{{
                            program_data.description }}</span></div>
                    </div>
                    <div v-if="program_data.additional_programs">
                        <div v-for="(aprogram, id, i) in program_data.additional_programs" :key="id">
                            <span class="numbered">{{ i + 2 }}. </span><span>{{ aprogram.description }}</span>
                        </div>
                    </div>
                </div>
                <br v-if="build_mode">
                <div class="question_title"><b>Ime članice/članic, ki sodelujejo pri izvedbi študijskega programa</b></div>
                <div class="question_description" v-if="program_data.name">
                    <div>
                        <div><span v-if="program_data.additional_programs" class="numbered">1. </span><span>{{
                            program_data.izvajajo }}</span></div>
                    </div>
                    <div v-if="program_data.additional_programs">
                        <div v-for="(aprogram, id, i) in program_data.additional_programs" :key="id">
                            <span class="numbered">{{ i + 2 }}. </span><span>{{ aprogram.izvajajo }}</span>
                        </div>
                    </div>
                </div>
                <br v-if="build_mode">
                <div class="question_title"><b>Študijsko leto</b></div>
                <div>
                    <div>
                        <div class="question_description" v-if="program_data.year">{{ program_data.year }}</div>
                    </div>
                </div>

                <div v-if="!build_mode && program_access.length > 0" class="question_title"><b>Obrazec lahko urejajo</b></div>
                <div v-if="!build_mode && program_access.length > 0 && program_data.name" class="question_description">
                    <div>
                        <div>
                            <ul>
                                <li v-for="user in program_access" :key="user.user_id">
                                    {{ user.name }} {{ user.surname }} ({{ user.email }})
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <br>
        </div>
        <!-- END OF FIRST PAGE EVALVATION -->

        <div v-else>
            <!-- SECOND PAGE EVALVATION -->
            <div v-if="!is_internal_questionnaire && (q_type == 1 || q_type == 4) && page_data[active_page_id] && page_data[active_page_id].order_number == 2"
                class="evalvation_question_holder">
                <div class="general_data">
                    <div class="question_title"><b>Temeljni cilji</b></div>
                    <div class="question_description" v-if="program_data.temeljni_cilji"><span
                            v-html="program_data.temeljni_cilji"></span></div>
                    <br v-if="build_mode">
                    <div class="question_title"><b>Splošne kompetence diplomanta</b></div>
                    <div class="question_description" v-if="program_data.splosne_kompetence_diplomanta"><span
                            v-html="program_data.splosne_kompetence_diplomanta"></span></div>
                    <br v-if="build_mode">
                    <div class="question_title"><b>Predmetno specifične kompetence</b></div>
                    <div class="question_description" v-if="program_data.predmetno_specificne_kompetence"><span
                            v-html="program_data.predmetno_specificne_kompetence"></span></div>
                    <div class="question_add_holder" v-if="build_mode && getUserType == 2 && page_data[active_page_id]">
                        <div class="as_link icon question_add" title="Dodaj vprašanje"
                            @click="showModal({ component: 'evalvation/CreateQuestionnaireQuestion', data: { q_type: q_type, order_number: 1 } })">
                            <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj vprašanje
                        </div>
                    </div>
                    <!-- For managers that can edit internal questionnaire, but not basic one -->
                    <br v-if="build_mode && getUserType == 1">
                </div>
            </div>

            <!-- FIRST PAGE ODDELKI -->
            <div v-else-if="!is_internal_questionnaire && (q_type !== 1 && q_type !== 4) && page_data[active_page_id] && page_data[active_page_id].order_number == 1"
                class="evalvation_question_holder">
                <div class="general_data">
                    <div class="question_title"><b>Oddelek</b></div>
                    <div>
                        <div class="question_description" v-if="department_data && department_data.name">{{
                            department_data.name
                        }}</div>
                    </div>

                    <br v-if="build_mode">

                    <!-- <div class="question_title"><b>Predstojnik oz. predstojnica oddelka</b></div>
                    <div class="question_description" v-if="department_data && department_data.predstojnik">{{
                        department_data.predstojnik }}</div>
                    <br v-if="build_mode">
                    <div class="question_title"><b>Predsednik oz. predsednica oddelčne komisije</b></div>
                    <div class="question_description" v-if="department_data && department_data.vodja_komisije">{{
                        department_data.vodja_komisije }}</div>
                    <br v-if="build_mode"> -->

                    <div class="question_title"><b>Študijski programi in smeri, ki se izvajajo na oddelku</b></div>
                    <div>
                        <div class="question_description" v-if="department_data && department_data_programs_string">{{
                            department_data_programs_string }}</div>
                    </div>

                    <br v-if="build_mode">

                    <div class="question_title"><b>Študijsko leto</b></div>
                    <div>
                        <div class="question_description" v-if="department_data && department_data.year">{{
                            department_data.year
                        }}</div>
                    </div>
                    
                    <br v-if="build_mode">
                    <br v-if="build_mode">
                </div>

                <div v-for="(question, index) in page_questions" :key="question.id" class="evalvation_question_holder">

                    <!-- predstojnik index 1, predsednik index 2, komisija index 3 -->
                    <div class="question_title_row">
                        <span class="as_link icon edit" title="Uredi vprašanje"
                            v-if="build_mode && (getUserType == 1 || getUserType == 2)">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({
                                component: 'evalvation/EditQuestionnaireQuestion',
                                data: { title: question.title, id: question.id, order_number: question.order_number, description: question.description, 
                                    breakk: question.break, q_type: q_type, images: question.images, skippable: question.skippable, 
                                    improvements: question.improvements, stopnja: question.stopnja, vrsta: question.vrsta }
                            })" />
                        </span>

                        <span class="question_title unnumbered">
                            <b>{{ question.title }}</b>
                        </span>
                    </div>

                    <div class="question_description" v-if="question.description && question.description !== ''"
                        v-html="question.description"></div>

                    <div class="general_data">
                        <div class="question_description">
                            <input v-if="index == 1 || index == 2" class="short_text" type="text" :class="textarea_sample"
                                :disabled="disable_input" :value="question_answer(question.id)" size="35"
                                @change="saveAnswer(question.id, $event)" />

                            <textarea v-if="index == 3" class="short_text" :class="textarea_sample" type="text" rows="4"
                                :disabled="disable_input" :value="question_answer(question.id)"
                                @change="saveAnswer(question.id, $event)" name="textarea_answer">
                            </textarea>
                        </div>
                    </div>
                </div>
            </div>

            <!-- all but first page of oddelki -->
            <div v-if="(!(!is_internal_questionnaire && (q_type !== 1 && q_type !== 4) && page_data[active_page_id] && page_data[active_page_id].order_number == 1)) && questionVisible(question.id, question.stopnja, question.vrsta)"
                v-for="(question, index) in page_questions" :key="question.id" class="evalvation_question_holder question">
                <ul-eval-question :build_mode="build_mode" :question_order_num="question.order_number" :hidden_questions_num="hidden_questions_num"
                    :q_type="q_type"></ul-eval-question>
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions } from 'vuex';
import Question from './EvalvationQuestionnaireQuestion.vue';

export default {
    name: 'EvalvationQuestionnaireQuestions',
    components: {
        'ul-eval-question': Question,
    },
    data: function () {
        return {
            rendered_questions: [],
            hidden_questions_num: 0
        }
    },
    props: {
        build_mode: {
            type: Boolean,
            required: true
        },
        q_type: {
            type: [String, Number],
            required: true
        }
    },
    watch: {
        page_data(){
            this.hidden_questions_num = 0;
            this.rendered_questions=[];
        }
    },
    computed: {
        // Selected questions in active page
        page_questions() {
            if (this.active_page_id && this.active_page_id !== 'RU' && this.$store.getters.evalvation_questionnaire_detail.pages) {
                if (this.$store.getters.evalvation_questionnaire_detail.pages[this.active_page_id])
                    return this.$store.getters.evalvation_questionnaire_detail.pages[this.active_page_id].questions;
                else
                    return this.$store.getters.evalvation_internal_questionnaire_detail.pages[this.active_page_id].questions;
            }
            return null;
        },
        // Selected questionnaire object for program
        page_data() {
            if (this.active_page_id && this.$store.getters.evalvation_questionnaire_detail.pages && this.$store.getters.evalvation_questionnaire_detail.pages[this.active_page_id])
                return this.$store.getters.evalvation_questionnaire_detail.pages;
            else if (this.active_page_id && this.$store.getters.evalvation_internal_questionnaire_detail.pages && this.$store.getters.evalvation_internal_questionnaire_detail.pages[this.active_page_id])
                return this.$store.getters.evalvation_internal_questionnaire_detail.pages;
            else
                return {};
        },

        is_internal_questionnaire() {
            return this.active_page_id && this.$store.getters.evalvation_internal_questionnaire_detail.pages && this.$store.getters.evalvation_internal_questionnaire_detail.pages[this.active_page_id];
        },

        //get IDs of all programs for this questionnaire
        programs_id() {
            if (this.q_type == 1 || this.q_type == 4)
                return this.$route.params.programs.toString().split(",");
            else
                return this.$route.params.department;
        },

        //list with users who have access to program
        program_access() {
            let access = this.program_data.access ? this.program_data.access : {};

            if (this.program_data.additional_programs) {
                for (let program of Object.values(this.program_data.additional_programs)) {
                    access = { ...access, ...program.access };
                }
            }

            //if only one, delete loged user
            if (Object.keys(access).length == 1)
                delete access[this.getUserID];

            return Object.values(access);
        },

        // Get program data
        program_data() {
            if (this.build_mode)
                return {};

            //if user is admin, pull program data from admins program object
            if (this.programs_id.length > 0 || Number.isInteger(this.programs_id)) {
                return this.$store.getters.evalvation_program_detail;
            }
        },

        // Get department data
        department_data() {
            if (this.build_mode)
                return {};
            return this.$store.getters.evalvation_department_detail;
        },

        // Selected department programs
        department_data_programs_string() {
            let name = '';
            if (Object.keys(this.department_data).length !== 0 && this.department_data.programs && Object.keys(this.department_data.programs).length !== 0) {
                for (let id in this.department_data.programs) {
                    name += this.department_data.programs[id].name +
                        ' (' + this.department_data.programs[id].ul_program_id + '), ';
                }
                name = name.slice(0, -2);
            }
            return name;
        },

        //id of active page
        active_page_id() {
            return this.$store.getters.active_q_page_id;
        },

        //get answers for this questionnaire
        questionnaire_data() {
            if (this.build_mode)
                return {};
            else {
                return this.$store.getters.questionnaire_data;
            }
        },

        //get answers for this questionnaire
        questionnaire_multiple_data() {
            return this.$store.getters.questionnaire_multiple_data;
        },

        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },

        // Get loged user id
        getUserID() {
            return this.$store.getters.getUser.id;
        },

        //class for sample or input
        textarea_sample() {
            if (this.build_mode)
                return 'textarea_sample';
            return '';
        },

        disable_input() {
            return this.getUserType == 3 || (this.build_mode || (this.getUserType < 2 && !this.is_internal_questionnaire && (this.q_type == 1 || this.q_type == 4)
                && (this.program_data.finished == 1 || this.program_data.submitted == 1 || this.program_data.disabled == 1)));
        }
    },

    methods: {
        ...mapActions([
            'showModal',
            'saveQuestionData',
            'saveDepartmentQuestionData'
        ]),

        questionVisible(question_id, stopnja, vrsta){
            const progst = this.program_data.stopnja;
            const progvr = this.program_data.vrsta;

            if(this.build_mode || (stopnja == 0 && vrsta == 'all')) return true;
            if(stopnja == 1 && (progst=='prva stopnja' || progst=='druga stopnja' || progst=='izpopolnjevanje' || progst=='504')) return true;
            if(stopnja == 3 && progst=='tretja stopnja') return true;
            if(vrsta == progvr) return true;

            if(!this.rendered_questions.includes(question_id)){
                this.hidden_questions_num++;
                this.rendered_questions.push(question_id);
            }

            return false;
        },

        //save answer
        saveAnswer(question_id, event) {
            if (this.q_type == 1 || this.q_type == 4) {
                let additional_programs = Array.from(this.programs_id).slice(1);
                this.saveQuestionData({ program_id: this.programs_id[0], question_id: question_id, value: event.target.value, additional_programs: additional_programs });
            }
            else
                this.saveDepartmentQuestionData({ department_id: this.programs_id, question_id: question_id, value: event.target.value });
        },

        multi_program_question_answer(question_id, program_id) {
            if (this.questionnaire_multiple_data[question_id]) {
                return this.questionnaire_multiple_data[question_id][program_id];
            }
            return '';
        },

        //save answer multi program
        multi_program_saveAnswer(question_id, program_id, event) {
            this.saveQuestionData({ program_id: program_id, question_id: question_id, value: event.target.value, multiple: true });
        },

        //get value of answer
        question_answer(id) {
            if (this.questionnaire_data[id] && this.questionnaire_data[id].answer)
                return this.questionnaire_data[id].answer;
            return '';
        },
    },
}
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";

/* Transicije */
.fade-enter-active {
    transition: opacity 0.3s;
}

.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
